var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('label',{class:_vm.title_width,attrs:{"for":_vm.name}},[_c('strong',{staticClass:"float-lg-right float-md-left"},[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.required ? '*' : ''))])]),(!_vm.disabled)?_c('div',{class:_vm.input_width},[_c('span',{staticClass:"form-text m-b-none text-success"},[_vm._v(" "+_vm._s(_vm.note)+" ")]),(_vm.config)?_c('editor',{attrs:{"api-key":"v7x5qn7z0hyym5dy146alcsec88p7mov8fkuic0eyevs76zx","name":_vm.name,"disabled":_vm.disabled || _vm.readonly,"readonly":_vm.readonly,"init":{
                    height: 500,
                    menubar: true,
                    plugins: [
                                'advlist autolink lists link image charmap',
                                'searchreplace visualblocks code fullscreen',
                                'print preview anchor insertdatetime media',
                                'paste code help wordcount table'
                            ],
                    file_picker_types: 'image',
                    automatic_uploads: false,
                    paste_data_images: true,
                    images_upload_handler: function(blobInfo, success, failure, progress){
                        success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
                    },
                    toolbar: 'undo redo ' +
                            '| removeformat ' +
                            '| formatselect fontselect fontsizeselect forecolor backcolor ' +
                            '| bold italic underline ' +
                            '| alignleft aligncenter alignright alignjustify ' +
                            '| bullist numlist outdent indent ' +
                            '| help' +
                            '| ' + _vm.toolbar_addon_buttons ,
                    content_style: "\n                            @font-face {\n                                font-family: 'LOccitaneSans-Bold';\n                                src: url('/fonts/custom/LOccitaneSans-Bold.woff') format('woff');\n                            }\n                             @font-face {\n                                font-family: 'LOccitaneSans-Regular';\n                                src: url('/fonts/custom/LOccitaneSans-Regular.woff') format('woff');\n                            }\n                             @font-face {\n                                font-family: 'LOccitaneSerif-Bold';\n                                src: url('/fonts/custom/LOccitaneSerif-Bold.woff') format('woff');\n                            }\n                             @font-face {\n                                font-family: 'LOccitaneSerif-BoldItalic';\n                                src: url('/fonts/custom/LOccitaneSerif-BoldItalic.woff') format('woff');\n                            }\n                             @font-face {\n                                font-family: 'LOccitaneSerif-Italic';\n                                src: url('/fonts/custom/LOccitaneSerif-Italic.woff') format('woff');\n                            }\n                             @font-face {\n                                font-family: 'LOccitaneSerif-Regular';\n                                src: url('/fonts/custom/LOccitaneSerif-Regular.woff') format('woff');\n                            }\n                          ",
                    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;'
                        + 'LOccitane Sans Bold=LOccitaneSans-Bold;'
                        + 'LOccitane Sans Regular=LOccitaneSans-Regular;'
                        + 'LOccitane Serif Bold=LOccitaneSerif-Bold;'
                        + 'LOccitane Serif BoldItalic=LOccitaneSerif-BoldItalic;'
                        + 'LOccitane Serif Italic=LOccitaneSerif-Italic;'
                        + 'LOccitane Serif Regular=LOccitaneSerif-Regular;'
                    ,


                    setup: _vm.toolbar_addon_buttons_f ? _vm.toolbar_addon_buttons_f :  function( editor ){}
                }},on:{"input":_vm._return},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_vm._e(),_c('span',{staticClass:"form-text m-b-none text-danger"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._v(" "+_vm._s(_vm.error)+" ")])])])],1):_c('div',{class:_vm.input_width},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticStyle:{"min-height":"30px","width":"100%"},domProps:{"innerHTML":_vm._s(_vm.content)}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }